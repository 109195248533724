// 人工报单
// import qs from "qs";
import Vue from "vue";
let request = Vue.prototype.$http;
let qs = Vue.prototype.$qs;
// 获取配送站点
export function siteList(commercial_id) {
  return request.post(
    "/sales/manual_order/station", {
      commercial_id: commercial_id,
    });
}
// 获取会员数据
export function getTabList(now_page, memberLength,id) {
  return request.post(
    "/sales/manual_order/custom_lst", {
      currentPage: now_page,
      currentLength: memberLength,
      commercial_id:id
    });
}    
// 搜索会员数据
export function memberSearch(dialogQuery, commercial_id) {
  return request.post(
    "/Client/member/search", {
      search: dialogQuery,
      commercial_id: commercial_id,
    });
}   
// 添加和修改地址
export function updateAddress(data) {
  return request.post(
    "/client/shipping_address/save",
    qs.stringify({
      data: data,
    })
  );
}
// 获取地址
export function getAddress(data) {
  return request.post(
    "/sales/manual_order/address",
    qs.stringify({
      member_id: data,
    })
  );
}
// 搜索与获取商品列表
export function listProduct(page, length, commercial_id, searchVal = null) {
  return request.post(
    "/sales/manual_order/product_lst",
    qs.stringify({
      currentPage: page,
      currentLength: length,
      commercial_id: commercial_id,
      search: searchVal
    })
  );
}
// 提交订单
export function commitOrder(data) {
  return request.post(
    "/sales/manual_order/add",
    qs.stringify({
      data: data
    })
  );
}
// 补单提交
export function added(data) {
  return request.post(
    "/sales/manual_order/added",
    qs.stringify({
      data: data
    })
  );
}

// 获取回桶品牌列表
export function barrel_list(data) {
  return request.post(
    "/sales/manual_order/barrel_list",
    qs.stringify({
      data: data
    })
  );
}

// 出库单列表和查询
export function outbound(data) {
  return request.post(
    "/sales/manual_order/outbound",
    // qs.stringify({
    //   data
    // })
    data
  );
}
// 添加退桶单
export function back_bucket(data) {
  return request.post(
    "/sales/manual_order/back_bucket",
    // qs.stringify({
    //   data
    // })
    data
  );
}
// 查询退桶单
export function back_bucket_lst(data) {
  return request.post(
    "/sales/manual_order/back_bucket_lst",
    // qs.stringify({
    //   data
    // })
    data
  );
}
// 查询经营商户列表
export function Operate_lst(data) {
  return request.post(
    "/Operate/Operate/lst",
    // qs.stringify({
    //   data
    // })
    data
  );
}
// 余额列表和搜索
export function balance(data) {
  return request.post(
    "/Operate/Operate/balance",
    // qs.stringify({
    //   data
    // })
    data
  );
}
// 收款统计
export function collection(data) {
  return request.post(
    "/Operate/Operate/collection",
    data
  );
}
// 送货统计
export function deliver(data) {
  return request.post(
    "/Operate/Operate/deliver",
    data
  );
}

// 商品统计
export function product(data) {
  return request.post(
    "/Operate/Operate/product",
    data
  );
}

// 员工推广统计
export function promotion(data) {
  return request.post(
    "/Operate/Operate/promotion",
    data
  );
}
// 员工推广详情
export function marketing(data) {
  return request.post(
    "/Operate/Operate/marketing",
    data
  );
}






