<template>
  <div>
    <div>
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
          <el-breadcrumb-item>自提空桶入库单</el-breadcrumb-item>
          <el-breadcrumb-item>销售开单</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="padding: 5px">
          <i class="el-icon-close icon" @click="closeClick"></i>
        </div>
      </div>
      <div>
        <div style="
          display: flex;
          border: 0;
          border-bottom: 1px solid #333;
          margin: 20px;
        ">
          <div style="
            height: 30px;
            line-height: 30px;
            padding: 0 15px;
            color: white;
            background-color: #222;
            font-size: 14px;
          ">
            订单信息
          </div>
        </div>
        <div>
          <p style="text-align: center; font-size: 30px; color: #333">
            自提空桶入单
          </p>
          <div style="
            display: flex;
            justify-content: center;
            color: #555;
            font-size: 14px;
          ">
            <div>
              <div>客户名称：{{ info.site_name }}</div>
              <div>制单日期：{{ info.create_time }}</div>
            </div>
            <div style="margin: 0 150px"></div>
            <div>
              <div>操作人：{{ info.operator }}</div>
              <div>订单备注：{{ info.notes }}</div>
            </div>
          </div>
        </div>
        <div style="margin: 10px 20px">
          <table class="table">
            <tr>
              <th v-for="th in ths" :key="th">
                {{ th }}
              </th>
            </tr>
            <tr class="table_tr">
              <td :colspan="8">合计金额</td>
              <td :colspan="6" style="color: red">{{ info.price }}</td>
              <td :colspan="2"></td>
              <td :colspan="3"></td>
            </tr>
          </table>
        </div>
        <div style="margin: 10px 20px">
          <table class="table">
            <tr>
              <th v-for="th in th1s" :key="th">{{ th }}</th>
            </tr>
            <tr>
              <td :colspan="5">合计</td>
              <td style="color: red">{{ info.amount }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <div style="
          display: flex;
          border: 0;
          border-bottom: 1px solid #333;
          margin: 50px 20px 20px 20px;
        ">
          <div style="
            height: 30px;
            line-height: 30px;
            padding: 0 15px;
            color: white;
            background-color: #222;
            font-size: 14px;
          ">
            商品信息
          </div>
        </div>

        <div style="margin: 10px 20px">
          <p style="
            text-align: center;
            font-size: 30px;
            color: #333;
            margin: 10px 0;
          ">
            销售开单
          </p>
          <table class="table">
            <tr>
              <th v-for="th in th2s" :key="th">{{ th }}</th>
            </tr>
            <tr style="height: 20px">
              <td>{{ tabletd.index }}</td>
              <td>{{ tabletd.number }}</td>
              <td>{{ tabletd.product_name }}</td>
              <td> <el-input v-model="tabletd.num" style="width:140px"></el-input> </td>
              <td> <el-input v-model="tabletd.sales_price" style="width:140px"></el-input></td>
              <td> {{ parseInt(tabletd.num) * tabletd.sales_price }} </td>
              <td> <el-input v-model="tabletd.freight" style="width:140px"></el-input> </td>
              <td> <el-input v-model="tabletd.remark" style="width:140px"></el-input> </td>
              <td>
                <div>
                  <i class="el-icon-edit"></i>
                  <span style="margin: 0 5px"></span>
                  <i class="el-icon-delete"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="11" style="padding: 10px 40px; text-align: left">
                <div>
                  <el-input v-model="addGoods" placeholder="请输入商品名称或货号" style="width: 15%; margin-right: 10px"></el-input>
                  <el-link icon="el-icon-plus" @click="productShow = true">添加商品</el-link>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">合计</td>
              <td></td>
              <td></td>
              <td></td>
              <td colspan="3"></td>
            </tr>
          </table>
          <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
            <span>优惠折扣：</span>
            <span style="width: 30%">应付金额：</span>
          </div>
        </div>

        <div>
          <div style="
            display: flex;
            border: 0;
            border-bottom: 1px solid #333;
            margin: 50px 20px 20px 20px;
          ">
            <div style="
              height: 30px;
              line-height: 30px;
              padding: 0 15px;
              color: white;
              background-color: #222;
              font-size: 14px;
            ">
              收款信息
            </div>
          </div>

          <div style="display: flex;margin: 20px;" class="informationClass">
            <el-col :span="5">
              <div>
                <p>商品金额：{{ parseInt(tabletd.num) * tabletd.sales_price }}</p>
                <p style="text-indent: 2em;">现金：<el-input style="width:30%;text-indent: 0em;" v-model="cash"
                    size="mini"></el-input></p>
                <p>累计欠款：0</p>
              </div>
            </el-col>
            <el-col :span="5">
              <div>
                <p>空桶金额：{{ info.amount }}</p>
                <p style="text-indent: 2em;">微信：<el-input style="width:30%;text-indent: 0em;" v-model="wechat"
                    size="mini"></el-input></p>
                <p>以往欠款：￥0.00</p>
              </div>
            </el-col>
            <el-col :span="5">
              <div>
                <p>烂桶金额：<el-input style="width:30%;" size="mini" v-model="tabletd.rotted_barrel"></el-input></p>
                <p style="text-indent: 1em;">支付宝：<el-input style="width:30%;text-indent: 0em;" v-model="alipay"
                    size="mini"></el-input></p>
                <p style="text-indent: 2em;">还款：</p>
              </div>
            </el-col>
            <el-col :span="5">
              <div>
                <p>应收金额：<span style="color: red">(0+0-0)0</span></p>
                <p>支出金额：<el-input style="width:30%;" size="mini" v-model="tabletd.payment_number"></el-input></p>
              </div>
            </el-col>
          </div>
        </div>
        <div style="display: flex;justify-content: flex-end;">
          <el-button type="primary" icon="el-icon-success" style="margin:30px" @click="commit">确认收款</el-button>
        </div>
      </div>
    </div>
    <AddProduct :productShow="productShow" @handleClose="handleClose" @addConfirm="addConfirm"
      @productClick="productClick" />
  </div>
</template>
<script>
import AddProduct from "@/components/addProduct/addProduct.vue";
export default {
  props: ['id'],
  components: { AddProduct },
  data() {
    return {
      productShow: false,
      cash: 0,
      wechat: 0,
      alipay: 0,
      info: {},
      ths: [
        "序号",
        "桶品牌",
        "入库包含烂桶",
        "烂桶",
        "兑换桶",
        "1比1",
        "2比1",
        "3比1",
        "补差价1比1",
        "补差价金额",
        "押桶",
        "押桶金额",
        "买桶",
        "买通金额",
        "退桶",
        "退桶金额",
        "欠桶",
        "还桶",
        "总入库",
      ],
      priceNum: 0,
      th1s: ["序号", "商品名", "桶品桶", "单价", "数量", "金额"],
      th2s: [
        "序号",
        "货号",
        "名称",
        "数量",
        "进货价",
        "金额",
        "运费/单件",
        "备注",
        "操作",
      ],
      td2s: [{}],
      price1Num: 0,
      addGoods: "",
      tabletd: {},
    };
  },
  async created() {
    // console.log(this.id);
    let id = this.id;
    this.info = (await this.$http.post("/Station/Barrel_Entry/data", {
      id
    })).data;
  },
  mounted() { },
  computed: {},
  methods: {
    async commit() {
      let query = {
        "id": this.id,
        "number": this.tabletd.number,
        "product_name": this.tabletd.product_name,
        "product_number": this.tabletd.num,
        "sales_price": this.tabletd.sales_price,
        // "product_sum": this.tabletd.product_sum,
        "freight": this.tabletd.freight,
        "product_notes": this.tabletd.remark,
        "cash": this.cash,
        "wechat": this.wechat,
        "alipay": this.alipay,
        "rotted_barrel": this.tabletd.rotted_barrel,
        "payment_number": this.tabletd.payment_number
      }
      console.log(query)
      let res = (await this.$http.post("/Station/Barrel_Entry/add_product", query)).data;
      console.log(res)
    },
    productClick(data) {
      this.tabletd = data
      this.tabletd.index = 1;
      this.tabletd.num = 1;
      this.tabletd.rotted_barrel = 0;
      this.tabletd.payment_number = 0
      this.tabletd.cash = 0
      this.tabletd.wechat = 0
      this.tabletd.alipay = 0
      // data.coupon = 0;
      // data.number = 1;
      // data.price = data.sales_price;
      // this.tabletd.push(data)
    },
    handleClose() {
      this.productShow = false;
    },
    addConfirm(data) {
      // console.log(data);
      // console.log("添加")
      // this.productShow = false;
      // const arr = data.map((item) => {
      //   return {
      //     product_name: item.product_name,
      //     specifications: item.specifications,
      //     price: item.price,
      //     coupon: 0,
      //     number: item.number,
      //     sales: 0,
      //   };
      // });
      // this.tabletd = [...this.tabletd, ...arr];
    },

    closeClick() {
      this.$emit("close", true);
    },
  },
};
</script>
<style lang="less" scoped>
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mt-10-20 {
  margin: 10px 20px;
}

.table {
  border: 1px solid #ddd;
  border-collapse: collapse;
  box-sizing: border-box;
  width: 100%;

  th {
    text-align: center;
    border: 1px solid #ddd;
    padding: 5px 6px;
    color: #333;
    font-weight: 600;
  }

  td {
    text-align: center;
    border: 1px solid #ddd;
    padding: 5px 0;
  }
}

.informationClass {
  p {
    margin: 7px 0;
  }
}

/deep/ .el-table__empty-block {
  height: 10px !important;
  min-height: 0 !important;
}
</style>
