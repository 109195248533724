<template>
  <div>
    <div class="file">
      <div class="file_level1" @click="allFile">
        <img
          v-if="isFileShow"
          style="width: 10px; height: 10px"
          :src="require('@/assets/mianAssets/文件夹减.png')"
          alt=""
        />
        <img
          v-if="!isFileShow"
          style="width: 10px; height: 10px"
          :src="require('@/assets/mianAssets/文件夹加.png')"
          alt=""
        />
        <img
          style="width: 22px; height: 22px; margin-left: 5px"
          :src="require('@/assets/mianAssets/文件夹.png')"
          alt=""
        />
        <span style="font-size: 12px">全部文件</span>
      </div>
      <div class="marinLeft">
        <ul v-if="isFileShow">
          <li
            v-for="label in classList"
            :key="label.id"
            @click="row1Click(label.id)"
          >
            <div class="row" @click="labelClick(label)">
              <span v-if="label.children.length !== 0">
                <img
                  v-if="row1 == label.id"
                  style="width: 10px; height: 10px"
                  :src="require('@/assets/mianAssets/文件夹减.png')"
                  alt=""
                />
                <img
                  v-else
                  style="width: 10px; height: 10px"
                  :src="require('@/assets/mianAssets/文件夹加.png')"
                  alt=""
                />
              </span>
              <img
                style="width: 16px; height: 16px"
                :src="
                  label.children.length == 0
                    ? require('@/assets/mianAssets/空白页.png')
                    : require('@/assets/mianAssets/文件夹.png')
                "
                alt=""
              />
              <a style="margin-left: 2px">{{ label.classify_name }}</a>
            </div>
            <div v-if="row1 == label.id">
              <div
                v-for="label1 in label.children"
                :key="label1.id"
                style="margin: 4px 10px"
                @click="row2Click(label1.id, $event)"
              >
                <div class="row" @click="labelClick(label1)">
                  <span v-if="label1.children.length !== 0"
                    ><img
                      v-if="row2 == label1.id"
                      style="width: 10px; height: 10px"
                      :src="require('@/assets/mianAssets/文件夹减.png')"
                      alt="" />
                    <img
                      v-else
                      style="width: 10px; height: 10px"
                      :src="require('@/assets/mianAssets/文件夹加.png')"
                      alt=""
                  /></span>
                  <img
                    style="width: 16px; height: 16px"
                    :src="
                      label1.children.length == 0
                        ? require('@/assets/mianAssets/空白页.png')
                        : require('@/assets/mianAssets/文件夹.png')
                    "
                    alt=""
                  />
                  <a style="margin-left: 2px">{{ label1.classify_name }}</a>
                </div>
                <div v-if="row2 == label1.id">
                  <div
                    v-for="label2 in label1.children"
                    :key="label2.id"
                    style="margin: 4px 20px"
                    class="row"
                    @click="labelClick(label2, $event)"
                  >
                    <img
                      style="width: 16px; height: 16px"
                      :src="require('@/assets/mianAssets/空白页.png')"
                      alt=""
                    />
                    <a style="margin-left: 2px">{{ label2.classify_name }}</a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["classList"],
  data() {
    return {
      row1: "",
      row2: "",
      isFileShow: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    allFile() {
      this.isFileShow = !this.isFileShow;
      this.$emit("allFile");
    },
    labelClick(label, e) {
      if (e) {
        e.stopPropagation();
      }
      this.$emit("labelClick", label.id);
    },
    row1Click(id) {
      if (this.row1 == id) {
        this.row1 = "";
        return;
      }
      this.row1 = id;
    },
    row2Click(id, e) {
      e.stopPropagation();
      if (this.row2 == id) {
        this.row2 = "";
        return;
      }
      this.row2 = id;
    },
  },
};
</script>
<style lang="less" scoped>
.file {
  padding: 20px;

  .file_level1 {
    display: flex;
    align-items: center;
    width: 120px;
    cursor: pointer;
    a {
      font-size: 14px;
      color: #333;
      font-family: "微软雅黑";
    }
  }

  .file_level2 {
    padding-left: 40px;
    margin-top: 3px;

    a {
      height: 20px;
      font-size: 14px;
      color: #333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}
.marinLeft {
  width: 225px;
  align-self: flex-start;

  margin-left: 30px;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    li {
      font-size: 12px;

      padding: 2px 0;
      cursor: pointer;
      .row {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        cursor: pointer;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
